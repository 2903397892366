<template>
  <v-container class="warehouse-list">
    <h1>{{ $t("warehouse.title") }}</h1>
    <v-row class="filters-row ma-0">
      <v-col cols="12" md="5">
        <AddressFilter
          v-if="google"
          :google="google"
          class="pa-2"
          @addressToSearchChanged="addressToSearchChanged"
          @resetNearWarehouse="resetNearWarehouse"
        />
      </v-col>
      <v-col cols="12" md="7" class="d-flex">
        <v-checkbox
          v-for="store in stores"
          :key="store.id"
          :value="store.id"
          :label="store.name"
          v-model="selectedStores"
          class="mr-3 font-weight-bold"
          @change="filterWarehouses"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="warehouseListFiltered && warehouseListFiltered.length > 0"
    >
      <v-col
        v-if="showListParsed"
        class="py-0 py-md-3"
        order="2"
        order-sm="1"
        cols="12"
        :sm="showListParsed ? '5' : '0'"
        :xl="showListParsed ? '4' : '0'"
      >
        <v-list class="warehouse-list-list" :height="mapHeight">
          <template v-for="(warehouse, index) in warehouseListFiltered">
            <v-list-item
              :key="warehouse.warehouseId"
              class="d-flex pt-1 wh-address-list"
              two-line
              dense
              @click="toggleInfoWindow(warehouse)"
            >
              <!-- @mouseenter="animateMarker(warehouse, true)"
                  @mouseleave="animateMarker(warehouse, false)" -->

              <v-list-item-avatar rounded="0" width="50px">
                <img
                  width="60"
                  height="60"
                  :src="`/img_layout/map_pins/${warehouse.storeId}.png`"
                  alt="Immagine pin negozio"
                  aria-hidden="true"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-left font-weight-bold mb-2">
                  {{ getWarehouseName(warehouse) }}
                </v-list-item-title>
                <v-list-item-subtitle class="warehouse-address">
                  {{ warehouse.address.address1 }},{{
                    warehouse.address.addressNumber
                  }}
                  {{ warehouse.address.city }} ({{
                    warehouse.address.province
                  }})
                  <div
                    v-if="
                      warehouse.metaData.warehouse_locator &&
                        warehouse.metaData.warehouse_locator.PHONE
                    "
                  >
                    Telefono: {{ warehouse.metaData.warehouse_locator.PHONE }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="warehouse.warehouseId"
              v-if="index !== warehouseListFiltered.length - 1"
            ></v-divider>
          </template>
        </v-list>
      </v-col>
      <v-col
        class="py-0 py-md-3"
        order="1"
        order-sm="2"
        cols="12"
        :sm="showListParsed ? '7' : '0'"
        :xl="showListParsed ? '8' : '0'"
      >
        <GmapMap
          ref="mapRef"
          :center="{
            lat: addressToSearch
              ? addressToSearch.latitude
              : 45.188610935103235,
            lng: addressToSearch ? addressToSearch.longitude : 9.15915671610878
          }"
          :zoom="zoomLevel"
          :style="{ width: mapWidth, height: mapHeight }"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false
          }"
        >
          <GmapMarker
            :ref="'warehousMarker_' + warehouse.warehouseId"
            v-for="(warehouse, i) in warehouseListFiltered"
            :key="warehouse.warehouseId"
            :position="
              google &&
                new google.maps.LatLng(
                  warehouse.address.latitude,
                  warehouse.address.longitude
                )
            "
            :clickable="true"
            :icon="`/img_layout/map_pins/${warehouse.storeId}.png`"
            @click="toggleInfoWindow(warehouse, i)"
          />
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick.stop="infoWinOpen = false"
          >
            {{ selectedWarehouse }}
            Contenuto
          </gmap-info-window>
          <gmap-circle
            v-if="addressToSearch"
            :center="{
              lat: addressToSearch.latitude,
              lng: addressToSearch.longitude
            }"
            :radius="addressToSearch.distance"
            :visible="true"
            :options="{ fillOpacity: 0.3, strokeWeight: 0.3 }"
          >
          </gmap-circle>
        </GmapMap>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        ><v-alert outlined type="error" class="mx-2">
          <strong> {{ $t("warehouse.noResults") }}</strong>
        </v-alert></v-col
      >
    </v-row>
  </v-container>
</template>
<style lang="scss">
.warehouse-list {
  .filters-row {
    background-color: #f4f3f3;
  }
  .warehouse-list-list {
    .wh-address-list {
      height: 60px;
      margin: 8px 0 8px 0;
      width: 100%;
      .v-avatar {
        height: unset !important;
      }
    }
    height: 380px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: initial;
    }
    overflow-y: auto;
    .v-list-item__title {
      text-transform: uppercase;
      color: var(--v-primary-base);
      font-size: 16px;
      font-weight: bold;
    }
    .warehouse-address {
      font-size: 14px;
    }
    .v-avatar {
      padding: 2px;
      margin: 0;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.gm-style-iw .gm-style-iw {
  max-height: 180px !important;
}
.gm-style {
  .gm-style-iw-c {
    max-height: 180px !important;
    .gm-style-iw-d {
      max-height: 180px !important;
    }
  }
}
#infoWindowDialog {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-primary-base);
  }

  a {
    cursor: pointer;
  }
}
</style>
<script>
import AddressFilter from "@/components/address/AddressFilter.vue";

import AddressService from "~/service/addressService";

import { gmapApi } from "vue2-google-maps";
import get from "lodash/get";

export default {
  name: "WarehouseMap",
  components: { AddressFilter },
  props: {
    regionId: { type: String, required: false },
    provinceId: { type: String, required: false },
    parentAddressClassId: { type: String, required: false },
    showLocker: { type: Boolean, default: false }
  },
  data() {
    return {
      showListParsed: true,
      showLockerParsed: false,
      warehouseList: [],
      warehouseListFiltered: [],
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null,
      selectedRegionId: null,

      addressToSearch: null,
      selectedStores: [],
      stores: [
        { id: 1, name: "Novacoop" },
        { id: 2, name: "Coop Liguria" },
        { id: 3, name: "Coop Lombardia" }
      ]
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "480px";
    },
    pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    },
    zoomLevel() {
      return this.$vuetify.breakpoint.xsOnly ? 5 : 6;
    }
  },
  methods: {
    getWarehouseName(wh) {
      return get(
        wh,
        "metaData.warehouse_detail.NAME",
        wh.name || wh.address.addressName
      );
    },
    resetNearWarehouse() {
      this.addressToSearch = null;
    },
    async addressToSearchChanged(addressToSearch) {
      this.addressToSearch = addressToSearch;
      await this.findWarehouse();
    },
    async findWarehouse(showLocker = false) {
      let searchParams;
      if (showLocker) {
        searchParams = { parent_address_type_id: 5 };
      } else {
        searchParams = {
          province_id: this.provinceId,
          parent_address_class_id: this.parentAddressClassId,
          region_id: this.regionId
        };
        if (this.addressToSearch) {
          searchParams["parent_latitude"] = this.addressToSearch.latitude;
          searchParams["parent_longitude"] = this.addressToSearch.longitude;
          searchParams["parent_distance"] = this.addressToSearch.distance;
        }
      }

      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
      this.warehouseListFiltered = JSON.parse(
        JSON.stringify(this.warehouseList)
      );
    },
    filterWarehouses() {
      if (this.selectedStores.length) {
        this.warehouseListFiltered = this.warehouseList.filter(warehouse =>
          this.selectedStores.includes(Number(warehouse.storeId))
        );
      } else {
        this.warehouseListFiltered = JSON.parse(
          JSON.stringify(this.warehouseList)
        );
      }
    },
    animateMarker(warehouse, status) {
      if (status) {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = this.google.maps.Animation.BOUNCE;
      } else {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = null;
      }
    },
    toggleInfoWindow(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };

      let infoWindowBtn =
        `<a aria-label="Indicazione stradali"
          title="Indicazione stradali" class='text-uppercase v-btn v-btn--text secondary--text v-size--small' target='system' href='https://www.google.it/maps/dir/${warehouse.name}@/${warehouse.address.latitude},${warehouse.address.longitude}'>` +
        this.$t("warehouse.directions") +
        `</a><span width="10px"></span> <a aria-label="Vai alla scheda del punto vendita"
          title="Vai alla scheda del punto vendita" class='v-btn primary v-size--small white--text' href='/punti-vendita/${warehouse.slug}'>
           ` +
        this.$t("warehouse.goTo") +
        `
          </a>`;
      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Open Sans, arial, sans-serif'>
          <h3 class='d-flex align-center' style='line-height: 1.2rem;'>
            ${this.getWarehouseName(warehouse)}
          </h3>
          <div class='mb-1'>
            ${warehouse.address.address1} ${
        warehouse.address.addressNumber
      } <br/>
            ${warehouse.address.city} ( ${warehouse.address.province} ) ${
        warehouse.address.postalcode
      } <br/>
          </div>
          ${infoWindowBtn}
        </div>`;

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
    }
  },
  mounted() {
    if (this.showLocker) {
      this.findWarehouse(this.showLocker);
    } else {
      this.findWarehouse();
    }
  }
};
</script>
